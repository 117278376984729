.post-container {
  position: relative;
  min-width: 60vw;
  max-width: 60vw;
  height: auto;
  border-radius: 14px;
  padding: 20px;
  margin-top: 25px;
  background-color: rgb(80, 80, 80);
  box-shadow: -5px -5px 9px rgba(248, 39, 53, 0.7);
}
.post-container a {
  text-decoration: none;
  margin: 10px 0px;
}
.post-container * {
  color: white;
}
.post-container button {
  height: auto;
  cursor: pointer;
  text-align: center;
  align-items: center;
  background-color: rgb(80, 80, 80);
  border: none;
  text-decoration: none;
  color: inherit;
  margin-bottom: 10px;
}

button.commentsButton {
  background-color: brown;
  width: 100%;
  border-radius: 14px;
  margin-top: 20px;
  padding: 10px;
}

.media-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 5px;
}
.video-container {
  width: 80%;
  padding-top: 56.25%;
  height: 0px;
  position: relative;
}
.video {
  position: absolute;
  top: 15%;
  left: 15%;
  width: 70%;
  height: 70%;
}
.post-url a {
  margin: 10px;
  color: black;
}
.post-image {
  position: relative;
}
.post-image a img {
  width: 70%;
  border-radius: 14px;
  border: solid black 1px;
  margin-bottom: 10px;
}
.subreddit-redirect {
  text-decoration: none;
}

.subreddits {
  margin: 10px 0px;
}

.selftext-container {
  font-size: 18px;
  line-height: 1.5;
  word-wrap: inherit;

  padding: 10px;
  width: max-content;
}

.comments h4 {
  background-color: rgba(142, 118, 32, 0.44);
  width: 100%;
  padding: 0px 30px 0px 10px;
  margin: 0px;
  border-radius: 14px 200% 14px 30px;
  text-align: left;
}
.comments p {
  background-color: burlywood;
  color: #3a2525;
  padding: 10px 10px;
  margin: 0px 10px 3px;
  border-radius: 0px 14px 14px 14px;
  text-align: left;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.5;
  font-size: 17px;
}
.comments {
  padding: 10px;
  word-wrap: break-word;
}

.comment-link {
  margin-top: 30px;
  justify-content: space-around;
  align-items: center;
}
.comment-link a {
  display: flex;
  text-decoration: none;
  color: red;
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  border-radius: 14px 14px 14px 14px;
  background-color: honeydew;
}
.comment-link a:hover {
  padding: 12px;
  box-shadow: -5px -5px 9px rgba(248, 39, 53, 0.7);
}
.comment-link a:active {
  padding: 12px;
  border: inset;
  box-shadow: -5px -5px 9px rgba(248, 39, 53, 0.7);
}

.replies {
  margin-top: 10px;
}

.reply-depth-1,
.reply-depth-2,
.reply-depth-3,
.reply-depth-4,
.reply-depth-5 {
  margin-left: 20px;
}
.reply-depth-1,
.reply-depth-3,
.reply-depth-5 {
  border-left: 1px solid #ccc;
}
.score {
  display: flex;
  background-color: white;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
  width: 90%;
  background-color: #3a2525;
  padding: 10px;
  text-align: left;
  border-radius: 14px 14px 0px 14px;
}
.author {
  display: flex;
  padding: 10px;
}
@media screen and (max-width: 900px) {
  .post-container {
    min-width: 75vw;
    max-width: 75vw;
  }
  .video {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .post-image img {
    min-width: 70vw;
    max-width: 85vw;
  }
  .selftext-container {
    max-width: 75vw;
  }
  .comments p {
    background-color: burlywood;

    line-height: 1;
    font-size: 12px;
  }
  .reply-depth-1,
  .reply-depth-2,
  .reply-depth-3,
  .reply-depth-4,
  .reply-depth-5 {
    margin-left: 5px;
  }
  .comments h4 {
    padding: 5px;
    margin: 0;
    border-radius: 14px 200% 14px 30px;
    text-align: left;
    font-size: x-small;
  }
}
