.sub-button {
  display: flex;
  text-align: center;
  width: 100%;
  align-items: center;
  text-decoration: none;
  border: outset;
  background-color: rgb(75, 75, 75);
  color: white;
  cursor: pointer;
}
.subreddits-container {
  overflow: hidden;
}

.list-item img {
  width: 30px;
  padding-right: 5px;
}
ul.subreddits {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  list-style: none;
  column-gap: 10px;
  row-gap: 10px;
  margin: 0px;
  padding: 10px 0px;
}
div.subreddits-container h1 {
  color: white;
  border: outset;
  background-color: black;
  margin: 0px;
  padding: 5px;
}
@media screen and (max-width: 400px) {
  .sub-button {
    border: none;
  }
  ul.subreddits {
    list-style: none;
    column-gap: 0px;
    row-gap: 2px;
    flex-direction: column;

    padding: 0px;
    text-align: center;
  }
}
