header {
  display: flex;
  height: min-content;
  background-color: rgb(30, 30, 30);
  justify-content: center;
  align-items: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 10px;
}

header h1 {
  color: white;
}

header div input {
  text-align: center;
}
header .heading {
  display: flex;
  align-items: flex-start;
}
header img {
  margin: 5px;
}
.search {
  border-radius: 24px;
  border: 1px solid #a9a9a9;
  font-size: 14px;
  padding: 4px 8px;
  text-align: center;
}

.search-button {
  height: 24px;
  width: 24px;

  align-items: center;
  justify-content: center;
  border: none;
  background: transparent;
  border-radius: 50%;
  cursor: pointer;
  font-size: 12px;
  transition: background-color 0.1s ease-in;
  background-color: red;
}

.search-button:hover,
.search-button:focus {
  background-color: #eaeaea;
}

.search-form {
  display: flex;
  padding: 10px 5px 10px 30px;
  align-items: center;
  column-gap: 10px;
}
